export default function dataLayerEngagement(event, position,title,cta) {
  if (typeof window !== "undefined") {
    if (window.dataLayer !== null) {
      window.dataLayer.push({
        event: event,
        module_postion: position,
        module_title: title,
        module_cta: cta,
        module_type: "static"
      })
    }
  }
}
