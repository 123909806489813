import React, { useState,useEffect } from "react"
import { Link } from "gatsby"
import "./style.scss"
import EmailNewsletter from "../EmailNewsletter"
import TwitterIcon from "../../images/assets/Socials/RoundTwitter.svg"
import FacebookIcon from "../../images/assets/Socials/RoundFacebook.svg"
import InstagramIcon from "../../images/assets/Socials/RoundInstagram.svg"
import { footerLinks } from "./mockedData"

const NewFooter = ({ isDoctorPortal, isReducedFooter }) => {
  const [california,setCalifornia] = useState(false)
  const [linksList, setLinksList] = useState(isReducedFooter ? footerLinks.filter(({title}) => title === "Resources" || title === "About Us") : footerLinks)

  const getTestedLinks = () => {
    return footerLinks.filter(({title}) => title !== "Shop by Face Shape")
  }

  useEffect(() => {
    setLinksList(isReducedFooter ? getTestedLinks().filter(({title}) => title === "Resources" || title === "About Us") : getTestedLinks())
  }, [isReducedFooter])
  const [airgapLoaded, setAirgapLoaded] = useState(false)

  // airgap script moved to async so need to check it's loaded
  const checkAirGap = (retries = 0) => {
    if (typeof window != undefined) {
      if(typeof window?.airgap?.getRegimes === "function") {
        setAirgapLoaded(true)
      } else if (retries < 10) {
        // console.log(`Retry ${retries} - airgap not found`)
        setTimeout(() => checkAirGap(retries+1), 1000)
      }
    }
  }
  useEffect(() => {checkAirGap()}, [])

  useEffect(()=> {
    if(typeof window != undefined) {
      if (airgapLoaded){
        const regimes = window.airgap.getRegimes();
        const regime_array = [...regimes]
        if(regime_array?.includes("CPRA")){
          setCalifornia(true)
        }
      }
    }
  }, [airgapLoaded])


  return (
    <div className={`new-footer ${isDoctorPortal ? "doctor-portal" : ""}`}>
      {!isDoctorPortal &&
      <>
      <div className="newsletter-block">
        <h4 className="text h4 white">Stay in the know</h4>
        <EmailNewsletter />
        <div className="socials">
          {/* <a >
            <TwitterIcon />
          </a> */}
          <a href="https://www.facebook.com/HubbleContacts" target="_blank" aria-label="Facebook">
            <FacebookIcon aria-hidden="true" />
          </a>
          <a href="https://www.instagram.com/hubblecontacts/?hl=en" target="_blank" aria-label="Instagram">
            <InstagramIcon aria-hidden="true" />
          </a>
        </div>
      </div>
      <div className="links-block">
        {linksList.map(({ title, links }, index) => (
          <div className="links-container" key={index}>
            <span className="title text bulky white">{title}</span>
            {links.map(({ label, to }, indexLink) => {
              const isExternalURL =
                to && (to.includes("https://") || !to.includes("http://"))

              if (isExternalURL) {
                return (
                  <a
                    className="link text white"
                    href={to}
                    key={`${index}-${indexLink}`}
                    target={label === "Privacy Policy" || label === "Your Privacy Choices" ? "_blank" : undefined}
                  >
                    {label}
                  </a>
                )
              }

              return (
                <Link
                  className="link text white"
                  to={to}
                  key={`${index}-${indexLink}`}
                >
                  {label}
                </Link>
              )
            })}
            {title === "About Us" &&
              <>
                {california &&
                  <>
                  <a href="https://privacy.hubblecontacts.com/policies?name=privacy-policy#9-jurisdiction-specific-information-and-consumer-rights" className="link text white" target="_blank">California Notice at Collection</a>
                  <a href="#" className="link text white" onClick={(event) => {event.preventDefault(); window.transcend.doNotSell(event.nativeEvent)}}>Do not sell my personal information</a>
                  </>
                }
              </>
            }
          </div>
        ))}
      </div>
      </>}
      <p className="rights-reserved text h9 white neue">
        ©2023 Hubble - All Rights Reserved
      </p>
    </div>
  )
}

export default NewFooter
