import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import "./style.scss"
import NewButton from "../NewButton"
import FBTrack from "../FBTrack"
import axios from 'axios'
import dataLayerEngagement from "../DataLayerEngagement"

const EmailNewsletter = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid Email").required(),
    }),
    onSubmit: values => {
      const email = values['email']

      dataLayerEngagement('newsletter signup')
      FBTrack.fbTrack('trackCustom', 'Lead')
      if (typeof(window._learnq) !== 'undefined') {
        window._learnq.push(['identify', {
          '$email' : email,
        }]);
      }

      axios.post(`https://hubble-api-v1.herokuapp.com/api/subscribe`, {email: email}).then((response) => {
      }).then(() => {
        setIsSuccess(true)
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className="email-newsletter">
      <div
        className="input-container"
        data-error={!!(formik.touched.email && formik.errors.email)}
      >
        <input
          type="email"
          aria-label="Enter Email Address"
          name="email"
          placeholder="Enter Email Address"
          value={formik.values.email}
          onChange={formik.handleChange}
          className="text h8 neue"
        />
        <NewButton
          type="submit"
          label="Submit"
          disabled={formik.touched.email && formik.errors.email}
        />
      </div>
      {!isSuccess && formik.errors.email && formik.touched.email && (
        <p className="message text n8 neutral white">
          Oops, something is not right...
        </p>
      )}
      {isSuccess && (
        <p className="message text n8 neutral white">
          Thank you. We'll keep you in the know.
        </p>
      )}
    </form>
  )
}

export default EmailNewsletter
