export const footerLinks = [
  {
    title: "Shop",
    links: [
      { label: "Contacts", to: "/contact-lenses/hubble" },
      { label: "Glasses", to: "/glasses/" },
      { label: "Sunglasses", to: "/sunglasses/" },
      { label: "Accessories", to: "/eye-care-accessories/" },
      { label: "Shop By Face Shape", to: "/glasses/shop-glasses-by-face-shape/"},
      { label: "Shop By Frame Shape", to: "/glasses/shop-glasses-by-frame-shape/"}
    ],
  },
  {
    title: "Shop by Face Shape",
    links: [
      { label: "View All", to: "/glasses/shop-glasses-by-face-shape/" },
      {
        label: "Glasses for Round Faces",
        to: "/glasses/shop-glasses-by-face-shape/glasses-round-face-shape/",
      },
      {
        label: "Glasses for Oval Faces",
        to: "/glasses/shop-glasses-by-face-shape/glasses-oval-face-shape/",
      },
      {
        label: "Glasses for Heart Faces",
        to: "/glasses/shop-glasses-by-face-shape/glasses-heart-face-shape/",
      },
      {
        label: "Glasses for Diamond Faces",
        to: "/glasses/shop-glasses-by-face-shape/glasses-diamond-face-shape/",
      },
      {
        label: "Glasses for Square Faces",
        to: "/glasses/shop-glasses-by-face-shape/glasses-square-face-shape/",
      },

    ],
  },
  {
    title: "Shop by Contact Lens Type",
    links: [
      { label: "View All", to: "/contact-lenses/" },
      { label: "Contacts for Astigmatism", to: "/contact-lenses/torics" },
      { label: "Daily Contact Lenses", to: "/contact-lenses/daily" },
      { label: "Weekly Contact Lenses", to: "/contact-lenses/weekly" },
      { label: "Monthly Contact Lenses", to: "/contact-lenses/monthly"},
    ],
  },
  {
    title: "Shop by Contacts Brand",
    links: [
      { label: "View All", to: "/contact-lenses/" },
      { label: "Hubble", to: "/contact-lenses/hubble/" },
      { label: "Acuvue 1-Day Moist", to: "/contact-lenses/acuvue-1-day/" },
      { label: "Acuvue Oasys", to: "/contact-lenses/acuvue-oasys/" },
      { label: "Acuvue Vita", to: "/contact-lenses/acuvue-vita/"},
      { label: "Bausch + Lomb Ultra", to: "/contact-lenses/bausch-lomb-ultra/"},
      { label: "Biotrue", to: "/contact-lenses/biotrue/"}
    ],
  },
  {
    title: "Shop by Frame Shape",
    links: [
      { label: "View All", to: "/glasses/shop-glasses-by-frame-shape/" },
      {
        label: "Round Shaped Frames",
        to: "/glasses/shop-glasses-by-frame-shape/round-framed-glasses/",
      },
      {
        label: "Square Shaped Frames",
        to: "/glasses/shop-glasses-by-frame-shape/square-framed-glasses/",
      },
      {
        label: "Rectangle Shaped Frames",
        to: "/glasses/shop-glasses-by-frame-shape/rectangle-framed-glasses/",
      },
      {
        label: "Cat Eye Shaped Frames",
        to: "/glasses/shop-glasses-by-frame-shape/cateye-framed-glasses/",
      },
    ],
  },
  {
    title: "About Us",
    links: [
      { label: "About Hubble", to: "/about-hubble/" },
      { label: "Reviews", to: "/contact-lenses/classic-by-hubble-lenses/#reviews" },
      { label: "Privacy Policy", to: "https://privacy.hubblecontacts.com" },
      { label: "Your Privacy Choices", to: "https://privacy.hubblecontacts.com/policies?name=welcome-to-the-vision-path-privacy-center" },
      { label: "Terms of Service", to: "https://tos.hubblecontacts.com/" },
      { label: "ADA Compliance", to: "/accessibility-statement/" },
      { label: "Media Inquires", to: "https://docs.google.com/forms/d/e/1FAIpQLSfJmUW0a8VXlJWzgHUTd7GUZU-bxhimlU2qZeiH1XZcbESX1w/viewform" },
    ],
  },
  {
    title: "Resources",
    links: [
      { label: "Hubble Library", to: "/blog/" },
      { label: "Vision Test", to: "/vision-tests/" },
      { label: "FAQ", to: "/faq/" },
      { label: "My Account", to: "/login/" },
      { label: "Contact Us", to: "/contact-us/" },
    ],
  },
]
