 function fbTrack(a,b,c) {
  if (typeof window !== "undefined") {
    if (window.fbq !== null) {
      if (typeof c === "object") {
        window.fbq(a, b, c)
      } else {
        window.fbq(a, b)
      }
    }
  }
}



function fbTrackSubscribe(orderId, price, brand) {
  if (typeof window !== "undefined") {
    if (window.fbq !== null) {
      window.fbq('track', 'Subscribe', {value: price , currency: 'USD', brand: brand}, {eventID: orderId});
    }
  }
}



 function fbPurchaseTrack(pixel) {
  if (typeof window !== "undefined") {
    if (window.fbq !== null) {
      window.fbq('trackSingle', pixel, 'Purchase', {
            value: 1,
            currency: 'USD',
            brand: 'Hubble'

      });
    }
  }
}


export default {fbTrack, fbPurchaseTrack, fbTrackSubscribe}
